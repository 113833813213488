import React, { Component } from 'react';
import { withTheme } from '../../contexts/AppContext';
import EachTableOrderRowController from '../../controllers/ordersControllers/EachTableOrderRowController';
import EachTableOrderRowView from '../../views/orders/EachTableOrderRowView';

class EachOrderRow extends Component {
    constructor(props) {
        super(props);
        //alert(JSON.stringify(this.props.restdata))
        this.state = {
            //orderdata : this.props.orderdata || null,
            orderOptions: [
                {
                    value: 'accepted',
                    label: 'Order Received',
                    color: 'default',
                },
                //{ value : "cooking", label : "Sent to Chef", color : "primary"},
                //{ value : "readytoserve", label : "Ready to Serve", color : "warning"},
                { value: 'served', label: 'Served', color: 'success' },
            ],
            //status : this.props.orderdata ? this.props.orderdata.status : "",
            clicked: false,
            showAuth: false,

            selectedStaff : null,
        };

        // defined in EachTableOrderRowController.js
        this.handleOneClick = EachTableOrderRowController.handleOneClick.bind(this)
        this.handleSelectChange = EachTableOrderRowController.handleSelectChange.bind(this)
        this.handleMoveBack =
					EachTableOrderRowController.handleMoveBack.bind(this)
        this.markServed = EachTableOrderRowController.markServed.bind(this);
        this.handleVendor = EachTableOrderRowController.handleVendor.bind(this);
    }

    render() {
        return(
            <EachTableOrderRowView parent={this} />
        )
    }
}

export default withTheme(EachOrderRow);