import React, { useState, useEffect } from 'react';
import { Row, Col, ListGroupItem, Button, Modal, ModalBody, Form, FormGroup, Label, Input } from 'reactstrap';
//import '../../dashboardComponents/OrderHistory/OrderHistory.css';
import { Icon } from 'react-icons-kit';
import { ic_lock  } from "react-icons-kit/md/ic_lock";
import { user  } from "react-icons-kit/icomoon/user";
import { arrowLeft } from "react-icons-kit/icomoon/arrowLeft";
import { close } from "react-icons-kit/fa/close";
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { precacheAndRoute } from 'workbox-precaching';

const Wrapper = styled.article`
  background-color: white;
  padding: 10px;
  h4,
  h5 {
    margin: 0px;
    margin-top: 0px !important;
    text-align: left;
  }
`;

const EachTableOrderRowView = ({parent}) => {
    const [accessCode, setAccessCode] = useState(null);
    const [error, setError] = useState(false);
    const { context } = parent.props;

    const [vendor, setVendor] = useState(null);
    const [showVendor, setSHowVendor] = useState(false);

    let order = parent.props.orderdata;
    var subtitle = "";
    let options = order.options_json ? JSON.parse(order.options_json) : null;
    if (options && options.length > 0) {
        options.forEach(option => {
            subtitle += option.name + ", ";
        });
    }

    const accessLevel = window.server_roles.supervisor;
     const  handleSubmit = async (e) => {
         try {
            e.preventDefault();
            if (accessCode === parent.state.selectedStaff.access_code) {
                await parent.handleSelectChange();
                parent.setState({
                    showAuth: false,
                    selectedStaff : null
                });
                return Promise.resolve();
            } else {
                toast.error("Invalid Access Code");
                return Promise.reject(new Error("Invalid access code"));
            }
        }   catch(err) {
            return Promise.reject(err);
        }
     };

    const handleVendor = async(e) => {
        try {
            e.preventDefault();
            if(vendor && vendor !== "") {
                await parent.handleVendor(vendor);
                setSHowVendor(false);
            }
            return;
        } catch (error) {
            alert("Something went wrong")
            return Promise.reject(error);
        }
    }

      useEffect(() => {
        if (accessCode && accessCode.length > 4) {
          setError("Access Code Should Be 4 Digits");
        } else {
          setError(false);
        }
      }, [accessCode]);

    if (order) {
        console.log({order})
        return (
            <ListGroupItem>
                <Modal isOpen={parent.state.showAuth}>
                    <ModalBody className='text-center'>
                        <div className='mb-4 border-bottom d-flex justify-content-between'>
                            <div className='d-flex'>
                                {parent.state.selectedStaff && <Icon icon={arrowLeft} size={30} onClick={() => {
                                    parent.setState({ selectedStaff: null})
                                }} />}
                                <h3 className="ml-3">Select Manager</h3>
                            </div>
                                <Icon icon={close} size={30} onClick={() => parent.setState({
                                    showAuth: false
                                })} />
                        </div>
                        <div>
                            {
                                parent.state.selectedStaff && <div>
                                        <Icon icon={ic_lock} size={50}/>
            <Form className="mt-4">
                <FormGroup>
                  <Label>ACCESS CODE</Label>

                  <Input
                    type="number"
                    maxLength={"4"}
                    className="text-center"
                    required
                    autoFocus
                    placeholder="Enter 4 Digit Staff Code"
                    onChange={(e) => {
                      setAccessCode(`${e.target.value}`);
                    }}
                    style={{
                      fontSize: "30px",
                      fontWeight: 800,
                      borderColor: error ? "red" : "gray",
                      color: error ? "#ff8099" : "gray",
                    }}
                  />
                </FormGroup>
                <Button className="btn-default w-50" disabled={!accessCode} onClick={handleSubmit}>
                  Enter
                </Button>
                {error ? (
                  <div className={`alert alert-danger mt-3 p-1`}>
                    <small style={{ fontSize: "17px" }}>{error}</small>
                  </div>
                ) : null}
              </Form>
                                    </div>
                            }
                            {
                                !parent.state.selectedStaff && parent.props.managers && parent.props.managers.length > 0 && parent.props.managers.map((val, i) => {
                                    return (
                                      <Wrapper
                                        key={i}
                                        className="row border-bottom"
                                        onClick={() =>
                                          parent.setState({
                                            selectedStaff: val,
                                          })
                                        }
                                      >
                                        <Icon icon={user} size={40} />
                                        <div className="ml-3">
                                          <h4>{val.name}</h4>
                                          <h5 className="text-muted">
                                            {val.email}
                                          </h5>
                                        </div>
                                      </Wrapper>
                                    );
                                })
                            }
                        </div>
                    </ModalBody>
                </Modal>
                {showVendor && <Modal isOpen={showVendor}>
                    <ModalBody className='text-center'>
                        <div className='mb-4 border-bottom d-flex justify-content-between'>
                            <div className='d-flex'>
                                <h3 className="ml-3">Enter Vendor Name</h3>
                            </div>
                        </div>
                        <Form className="mt-4">
                            <FormGroup>
                            <Label>Vendor Name</Label>
                            <Input
                                type="text"
                                className="text-center"
                                required
                                autoFocus
                                placeholder="Enter Vendor name"
                                onChange={(e) => setVendor(e.target.value)}
                                style={{
                                    fontSize: "30px",
                                    fontWeight: 800,
                                    borderColor: "gray",
                                    color: "gray",
                                }}
                            />
                            </FormGroup>
                            <Button className="btn-default w-50" disabled={!vendor || vendor === ""} onClick={handleVendor}>
                            Submit
                            </Button>
                        </Form>
                    </ModalBody>
                </Modal>}
                {order.status !== "cancelled" ? 
                    <Row onClick={parent.handleOneClick.bind(parent)}>
                        <Col md={3} xs={3}>
                            {order.quantity}
                        </Col>
                        <Col md={5} xs={5}>
                            {order.item_id ? order.item_id.name : "Item Deleted from DB"}
                            <p>{subtitle}</p>
                            {order.item_specifics ? <small>{"Instructions : " + order.item_specifics}</small> : null}
                            {order.vendor ? <small>{"Vendor : " + order.vendor}</small> : null}
                        </Col>

                        <Col md={3} xs={3}>
                        {order.status === "waiting" ? 
                            <Button bsStyle="danger" onClick={() => parent.props.markReject(order.id)}>Reject</Button> : 
                            parent.state.clicked ?
                            <>
                            <Button 
                                className='btn-danger'
                                disabled={parent.props.context.selectedStaff && window.server_roles[parent.props.context.selectedStaff.server_role] < accessLevel} onClick={parent.handleSelectChange.bind(parent)}
                            >Cancel Item</Button>
                            {order.status === "readytoserve" ? 
                            <Button 
                                className='btn-danger'
                                onClick={() => parent.markServed(order.id)}
                            >Serve Item</Button> : null}
                            <Button 
                                className='btn-info'
                                disabled={parent.props.context.selectedStaff && window.server_roles[parent.props.context.selectedStaff.server_role] < accessLevel} onClick={() => setSHowVendor(true)}
                            >Change Vendor</Button>
                            </>
                            :
                            <p>{window.rupeeSym + parseFloat(order.item_total).toFixed(2)}</p>
                        }
                        </Col>
                    </Row> : 
                    <Row onClick={parent.handleOneClick.bind(parent)}>
                        <Col md={3} xs={3}>
                            <s>{order.quantity}</s>
                        </Col>
                        <Col md={5} xs={5}>
                            <s>{order.item_id ? order.item_id.name : "Item Deleted from DB"}</s>
                            <p><s>{subtitle}</s></p>
                            {order.item_specifics ? <small><s>{"Instructions : " + order.item_specifics}</s></small> : null}
                            {order.vendor ? <small>{"Vendor : " + order.vendor}</small> : null}
                        </Col>

                        <Col md={3} xs={3}>
                        {parent.state.clicked ?
                            <>
                                <Button 
                                    className='btn-success'
                                    disabled={parent.props.context.selectedStaff && window.server_roles[parent.props.context.selectedStaff.server_role] < accessLevel} onClick={parent.handleMoveBack.bind(parent)}
                                >Move Back</Button>
                            </>
                            :
                            <p><s>{window.rupeeSym + parseFloat(order.item_total).toFixed(2)}</s></p>
                        }
                        </Col>
                    </Row>
                }
            </ListGroupItem>
        )
    } else {
        return (<h4>Loading ... </h4>)
    }
}

export default EachTableOrderRowView;
