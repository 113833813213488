// file: Registration.jsx

import React, { Component } from 'react';
import { AppContext, withTheme } from '../../contexts/AppContext';
import io from '../../socket-client';
import EachOrderController from '../../controllers/ordersControllers/EachOrderController';
import EachOrderView from '../../views/orders/EachOrderView';
// import Axios from 'axios';

class EachOrder extends Component {
    constructor(props) {
        super(props);
        this.timer = null;
        this.tableOptions =
            this.props.context &&
            this.props.context.restdata &&
            this.props.context.restdata.tables &&
            this.props.context.restdata.tables.map((eachtable) => {
                let tabOption = {
                    label: eachtable.table_name,
                    value: eachtable.id,
                };
                return tabOption;
            });

        this.state = {
            edit: false,
            orderdata: null,
            showModal: false,
            showImgModal: false,
            showCheckoutModal: false,
            showDispatchModal: false,
            showCancelDeliveryModal: false,
            showOrderModal: false,
            showPinModal: false,
            checkoutStatus: false,
            goNewOrder: false,

            img: null,
            io,
            server: null,
            pin: null,
            mode: 'assign',

            newTableID: null,
            showTableChangeModal: false,

            discount: 0.0,

            now: new Date(),
        };

        // this.props.context.io.socket.on(
        //     'orderupdate',
        //     function (data) {
        //         if (data === this.props.orderID) {
        //             this.getOrder();
        //         }
        //     }.bind(this)
        // );

        this.props.context.io.socket.on(
            'ordercheckout',
            function (data) {
                if (data === this.props.orderID) {
                    this.props.loadRestaurantDetails();
                    this.setState({ orderdata: null });
                }
            }.bind(this)
        );

        this.props.context.io.socket.on(
            'orderconfirm',
            function (data) {
                if (data === this.props.orderID) {
                    this.getOrder();
                }
            }.bind(this)
        );

        // defined in EachOrderController.js
        this.closeModal = EachOrderController.closeModal.bind(this);
        this.closeCheckoutModal = EachOrderController.closeCheckoutModal.bind(
            this
        );
        this.openCheckoutModal = EachOrderController.openCheckoutModal.bind(
            this
        );
        this.closeDispatchModal = EachOrderController.closeDispatchModal.bind(
            this
        );
        this.openDispatchModal = EachOrderController.openDispatchModal.bind(
            this
        );
        this.closeCancelDeliveryModal = EachOrderController.closeCancelDeliveryModal.bind(
            this
        );
        this.openCancelDeliveryModal = EachOrderController.openCancelDeliveryModal.bind(
            this
        );
        this.closeOrderModal = EachOrderController.closeOrderModal.bind(this);
        this.openOrderModal = EachOrderController.openOrderModal.bind(this);
        this.onChange = EachOrderController.onChange.bind(this);
        this.changeTable = EachOrderController.changeTable.bind(this);
        this.handleTableSelect = EachOrderController.handleTableSelect.bind(
            this
        );
        this.createOrder = EachOrderController.createOrder.bind(this);
        this.getOrder = EachOrderController.getOrder.bind(this);
        this.applyDiscount = EachOrderController.applyDiscount.bind(this);
        this.finalizeOrder = EachOrderController.finalizeOrder.bind(this);
        this.dispatchOrder = EachOrderController.dispatchOrder.bind(this);
        this.cancelDeliveryOrder = EachOrderController.cancelDeliveryOrder.bind(
            this
        );
        this.markReject = EachOrderController.markReject.bind(this);
        this.markAcceptAll = EachOrderController.markAcceptAll.bind(this);
        this.markReadyForPickup = EachOrderController.markReadyForPickup.bind(this);
        this.markPrepareOrder = EachOrderController.markPrepareOrder.bind(this);
        this.markOrderReady = EachOrderController.markOrderReady.bind(this);
        this.makeAdjustments = EachOrderController.makeAdjustments.bind(this);
    }

    // componentDidUpdate(prevProps) {
    //     if (prevProps.refresh !== this.props.refresh) {
    //         this.getOrder();
    //     }
    // }

    componentDidMount() {
        this.getOrder();
        // Axios(window.kumbaURL).then((fooddata) => {
        //   let alldata = fooddata;
        //   console.log(alldata)
        // });

        // this.timer = setInterval(() => {
        //     this.setState({ now: new Date() });
        // }, 1000);
    }

    render() {
        if (
					this.state.orderdata &&
					(!this.props.search ||
						this.props.search === '' ||
						(this.props.search &&
							(this.state.orderdata.user.user_id.includes(
								this.props.search.toLowerCase()
							) ||
								this.state.orderdata.user.first_name.toLowerCase().includes(
									this.props.search.toLowerCase()
								))))
				) {
					return (
						<EachOrderView parent={this} orderdata={this.state.orderdata} />
					)
				} else {
					return null
				}
    }
}

var AppEachOrder = withTheme(EachOrder);
export default AppEachOrder;
