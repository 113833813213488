import React, { Component, Suspense } from 'react';
import io from './socket-client';
import { AppContext, AppContextProvider } from './contexts/AppContext';
import { withTheme } from './contexts/AppContext';
import "./assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/scss/argon-dashboard-react.scss";
// import "react-datepicker/dist/react-datepicker.css";
// import './App.scss'

import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';

 import { ToastContainer } from "react-toastify";
// import loadingPng from './img/loading.png'
import { Spinner } from 'react-activity'
// import TopWave from './img/topWave.svg'
// import BottomWave from './img/bottomWave.svg'


//Custom CSS
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'react-select/dist/react-select.css';
import 'react-toastify/dist/ReactToastify.css';

import register from './registerServiceWorker';
// import StripeRedirect from './StripeRedirectWrapper';
// import Navbar from './components/Header/Header';
// import Sidenav from './views/test/Sidenav';
import OrderHistory from './dashboardComponents/OrderHistory/OrderHistory';
import 'react-activity/dist/react-activity.css';
import TimeSlot from './views/TimeSlot/TimeSlot';
import './App.css';
import Layout from './components/Layout/Layout';
import './App.css';
import './MediaQuery.css';
import NewProfile from './pages/Profile/NewProfile';
import OfflienPopup from './components/OfflinePopup/OfflinePopup';
import Loyalty from './pages/Loyalties/Loyalty';
//Import components lazy
const SignUpFinal = React.lazy(() => import('./dashboardComponents/signUp/SignUp'));
const SignUpDetails = React.lazy(() =>
    import('./dashboardComponents/signUp/details/SignUpDetails')
);
const Profile = React.lazy(() => import('./pages/Profile/Profile'));
const Menu = React.lazy(() => import('./dashboardComponents/mainPage/Menu'));
// const Order = React.lazy(() => import('./dashboardComponents/orders/Orders'));
// const Menu = React.lazy(() => import('./dashboardComponents/mainPage/Menu'));
const Order = React.lazy(() => import('./dashboardComponents/orders/Orders'));
const Chef = React.lazy(() => import('./dashboardComponents/kitchen/Kitchen'));
const Accounts = React.lazy(() => import('./views/accounts/AccountsView'));
// const Guests = React.lazy(() => import('./dashboardComponents/guests/Guests'));
const Cart = React.lazy(() => import('./dashboardComponents/menu/Cart'));
const Servers = React.lazy(() => import('./dashboardComponents/servers/Servers'));
const Menu_Order = React.lazy(() => import('./dashboardComponents/menu/Menu'));
// const Combos = React.lazy(() => import('./dashboardComponents/combos/Combos'));
const Offers = React.lazy(() => import('./dashboardComponents/offers/Offers'));
const Tables = React.lazy(() => import('./dashboardComponents/tables/Tables'));
const Events = React.lazy(() => import('./dashboardComponents/packages/Events'));
const Reservation = React.lazy(() => import('./views/reservations/Reservation'));

const Feedback = React.lazy(() =>
    import('./views/feedback/Feedback')
);

export default class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      io: io,
      isLoggedin: false
    }
    this.setIsLoggedin = this.setIsLoggedin.bind(this)
  }

  setIsLoggedin(userState) {
    this.setState({ isLoggedin: userState })
  }


  componentDidMount() {
    register();
  }

  render() {
    return (
        <AppContextProvider>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                pauseOnVisibilityChange
                draggable
                pauseOnHover
            />
            <div id="notify_sound"></div>
            <Router>
                <div>
                    {/* Wave designs */}
                    {/* <img src={TopWave} style={{ zIndex: 3, top: 0, right: 0, position: 'fixed' }} /> */}
                    {/* <img src={BottomWave} style={{ zIndex: 0, bottom: 0, left: 0, position: 'fixed' }} /> */}

                    {/* TopBar */}
                    {/* {this.state.isLoggedin && <Navbar />} */}

                    {/* Main Container */}
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'stretch',
                            overflow: 'hidden',
                        }}
                    >
                        {/* ---- sidenav---- */}
                        {/* {this.state.isLoggedin && <Sidenav />} */}

                        {/* --- Main Content --- */}
                        <OfflienPopup />
                        <div
                            id="content"
                            className="w-100"
                            // style={{ backgroundColor: '#F8F8F8' }}
                        >
                            <Suspense
                                fallback={
                                    <Layout>
                                        <div
                                            style={{
                                                width: '100%',
                                                textAlign: 'center',
                                                marginTop: 300,
                                            }}
                                        >
                                            {/* <img style={{ width: 200, height: 200 }} src={loadingPng} alt='loading-png' /> */}
                                            <Spinner size={30} />
                                        </div>
                                    </Layout>
                                }
                            >
                                <div className="main-content">
                                    <Switch>
                                        <Route
                                            exact
                                            path="/"
                                            component={() => (
                                                <Redirect to="/login" />
                                            )}
                                        />
                                        <Route
                                            exact
                                            path="/login"
                                            component={() => (
                                                <SignUpFinal
                                                    setIsLoggedin={
                                                        this.setIsLoggedin
                                                    }
                                                />
                                            )}
                                        />
                                        {/* {process.env.REACT_APP_COUNTRY === "IN" ? null : <Route exact path="/connect/stripe" component={StripeRedirect} />} */}
                                        <PrivateRoute
                                            exact
                                            path="/profile"
                                            // component={Profile}
                                            component={NewProfile}
                                        />
                                        <UserRoute
                                            exact
                                            path="/details"
                                            component={() => (
                                                <SignUpDetails add={true} />
                                            )}
                                        />
                                        {/* 
                      <PrivateRoute exact path="/orders" component={Order} /> */}

                                        {/* <PrivateRoute exact path="/kitchen" component={() => {
                        return <Layout pageName='Kitchen'>
                          <div className='text-center mt-5'>
                            <h1>kitchen Page</h1>
                          </div>
                        </Layout>
                      }}/> */}
                                        <PrivateRoute
                                            exact
                                            path="/kitchen"
                                            component={Chef}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/feedback"
                                            component={Feedback}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/menu"
                                            component={Menu}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/slot"
                                            component={TimeSlot}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/offers"
                                            component={Offers}
                                        />

                                        <PrivateRoute
                                            exact
                                            path="/neworder"
                                            component={Menu_Order}
                                        />

                                        <PrivateRoute
                                            exact
                                            path="/events"
                                            component={Events}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/orders"
                                            component={Order}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/tables"
                                            component={Tables}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/staff"
                                            component={Servers}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/reservations"
                                            component={Reservation}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/loyalty"
                                            component={Loyalty}
                                        />

                                        <PrivateRoute
                                            exact
                                            path="/history"
                                            component={OrderHistory}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/showcart"
                                            component={Cart}
                                        />
                                        <PrivateRoute
                                            exact
                                            path="/accounts"
                                            component={Accounts}
                                        />
                                        {/* <PrivateRoute exact path="/reservations" component={Reservation} />
                      <PrivateRoute exact path="/staff" component={Servers} />
                      
                      {/* <PrivateRoute exact path="/neworder" component={Menu_Order} />
                      
                      <PrivateRoute exact path="/guests" component={Guests} />
                      
                      <PrivateRoute exact path="/combos" component={Combos} />
                      
                      <PrivateRoute exact path="/orders/history" component={OrderHistory} /> */}
                                    </Switch>
                                </div>
                            </Suspense>
                        </div>
                    </div>
                </div>
            </Router>
        </AppContextProvider>
    );
  }
}

const PrivateRoute = ({ component: Component, ...rest }) => (
  <AppContext.Consumer>
    {(context) =>
      <Route
        {...rest}
        render={(props) =>
          context.isRestDone ? (
            <Component {...props} context={context} />
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location }
              }}
            />
          )
        }
      />
    }
  </AppContext.Consumer>
);

const UserRoute = ({ component: Component, ...rest }) => (
  <AppContext.Consumer>
    {(context) =>
      <Route
        {...rest}
        render={(props) =>
          context.token ? (
            <Component {...props} context={context} />
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location }
              }}
            />
          )
        }
      />
    }
  </AppContext.Consumer>
);

const PublicRoute = ({ component: Component, ...rest }) => (
  <AppContext.Consumer>
    {(context) =>
      <Route
        {...rest}
        render={(props) =>
          context.isRestDone ?
            (
              <Redirect
                to={{
                  pathname: "/kitchen",
                  state: { from: props.location }
                }}
              />
            ) : (
              <Component {...props} context={context} />
            )
        }
      />
    }
  </AppContext.Consumer>
);