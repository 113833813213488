import React from 'react'
import axios from 'axios';
import {toast} from 'react-toastify';

export default class EachTableOrderRowController {
	static handleOneClick() {
		// if(this.props.canCancel) {
		this.setState({ clicked: !this.state.clicked })
		// }
	}

	static async handleSelectChange() {
		try {
			let url = window.kumbaURL + '/orders/delete/' + this.props.orderdata.id
			let header = { 'foodbots-header': this.props.token }
			var toastId = toast('Cancelling ....', {
				autoClose: false,
				closeButton: false,
			})
			var response = await axios({
				url,
				headers: header,
				method: 'DELETE',
				data: {
					staff: this.props.selectedStaff ? this.props.selectedStaff.id : null,
				},
			})
			this.props.getOrder(this.props.orderID)
			toast.update(toastId, {
				render: 'Cancelled Item.',
				type: toast.TYPE.SUCCESS,
				autoClose: 1000,
				closeButton: true,
			})
		} catch (err) {
			this.setState({ error: err })
			toast.update(toastId, {
				render: 'Could not cancel.',
				type: toast.TYPE.ERROR,
				autoClose: 3000,
				closeButton: true,
			})
		}
	}

	static async handleMoveBack() {
		try {
			let url = window.kumbaURL + '/orders/moveback/' + this.props.orderdata.id
			let header = { 'foodbots-header': this.props.token }
			var toastId = toast('Moving back ....', {
				autoClose: false,
				closeButton: false,
			})
			var response = await axios({
				url,
				headers: header,
				method: 'PUT',
				data: {
					staff: this.props.selectedStaff ? this.props.selectedStaff.id : null,
				},
			})
			this.props.getOrder(this.props.orderID)
			toast.update(toastId, {
				render: 'Moved back Item.',
				type: toast.TYPE.SUCCESS,
				autoClose: 1000,
				closeButton: true,
			})
		} catch (err) {
			this.setState({ error: err })
			toast.update(toastId, {
				render: 'Could not move back.',
				type: toast.TYPE.ERROR,
				autoClose: 3000,
				closeButton: true,
			})
		}
	}

	static async handleVendor(vendor) {
		try {
			let url = window.kumbaURL + '/orders/updatevendor'
			let header = { 'foodbots-header': this.props.token }
			var toastId = toast('Updating Vendor ....', {
				autoClose: false,
				closeButton: false,
			})
			var response = await axios({
				url,
				headers: header,
				method: 'PUT',
				data: {
                    sub_order_id: this.props.orderdata.id,
                    vendor: vendor
				},
			})
			this.props.getOrder(this.props.orderID)
			toast.update(toastId, {
				render: 'Updated vendor',
				type: toast.TYPE.SUCCESS,
				autoClose: 1000,
				closeButton: true,
			})
		} catch (err) {
			this.setState({ error: err })
			toast.update(toastId, {
				render: 'Could not update vendor',
				type: toast.TYPE.ERROR,
				autoClose: 3000,
				closeButton: true,
			})
		}
	}

	static async markServed(id) {
		let header = { 'foodbots-header': this.props.token }
		var toastID = toast('Completing order ...', {
			autoClose: false,
			closeButton: false,
		})
		try {
			var response = await axios({
				url: window.kumbaURL + '/orders/markserved/' + id,
				headers: header,
				method: 'PUT',
				data: {
					staff: this.props.selectedStaff ? this.props.selectedStaff.id : null,
				},
			})
			var jsondata = response.data
			toast.update(toastID, {
				render: 'Finalized order successfully',
				type: toast.TYPE.SUCCESS,
				autoClose: 1000,
				closeButton: true,
			})
		} catch (error) {
			this.setState({ error: error })
			toast.update(toastID, {
				render: 'Failed to finalize order',
				type: toast.TYPE.ERROR,
				autoClose: 1000,
				closeButton: true,
			})
		}
	}
}
