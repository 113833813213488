import React, { useEffect, useState } from "react";

import { Icon } from "react-icons-kit";
import { ic_menu } from "react-icons-kit/md/ic_menu";
import { withTheme } from "../../contexts/AppContext";
import { ic_notifications } from "react-icons-kit/md/ic_notifications";
import { ic_lock } from "react-icons-kit/md/ic_lock";
import $ from "jquery";
import { Button, Modal, ModalBody } from "reactstrap";
// import Notifications from "../../views/notifications/Notifications";
import { ic_keyboard_backspace } from "react-icons-kit/md/ic_keyboard_backspace";
import { ic_store } from "react-icons-kit/md/ic_store";
import { toast } from "react-toastify";
import ServersListPopup from '../../components/PermissionsPopup/ServersListPopup';



export default withTheme(({ pageName, context, stickyComponent, back }) => {
  const [notification, setNotification] = useState(false);
  const subRestData = context.restdata
    ? context.restdata.sub_restaurants
    : null;
  const [state, setState] = useState({
    subRest: false,
  });

    // if(process.env.NODE_ENV === "production"){
    //   document.addEventListener("visibilitychange", () => {
    //     if (context.selectedStaff && context.staff_is_authorized) {
    //       context.setContextState({
    //         selectedStaff: null,
    //         staff_is_authorized: false,
    //       });
    //     }
    //   });
    // }

  useEffect(() => {
    if (context.sideNav) {
      $("#sticky").slideUp(100);
    } else {
      $("#sticky").slideDown(100);
    }
  }, [context.sideNav]);

  return (
    <div
      style={{
        position: "fixed",
        zIndex: "100",
      }}
      className="w-100"
    >
                <ServersListPopup />

      <nav
        style={{
          background: window.primaryColor,
          display: "flex",
          justifyContent: "space-between",
        }}
        className="navbar navbar-expand-lg navbar-dark bg-default shadow"
      >
        <Modal isOpen={state.subRest}>
          <ModalBody>
            <div className="d-flex justify-content-between mb-4">
              <h1>Switch Restaurant</h1>
              <Button
                onClick={() =>
                  setState({
                    ...state,
                    subRest: false,
                  })
                }
                color="danger"
              >
                Close
              </Button>
            </div>
            {subRestData && subRestData.length === 0 ? (
              <div className="text-center">No Sub Restaurant</div>
            ) : null}
            {context.mainrestdata ? (
              <div
                onClick={() => {
                  setState({ ...state, subRest: false });
                  context.selectRestaurant(
                    context.mainrestdata.restdata.restaurant_id
                  );
                  toast.success(
                    "Switched to " + context.mainrestdata.restdata.name
                  );
                }}
                className={`rounded p-4 ${
                  context.selectedRestID ===
                  context.mainrestdata.restdata.restaurant_id
                    ? "border-warning"
                    : ""
                } mb-3 text-center bg-default `}
                style={{
                  backgroundImage: `url(${context.mainrestdata.restdata.image})`,
                  backgroundSize: "cover",
                  backgroundPosition: "10px",
                  border: "4px solid",
                }}
              >
                <h1 className="m-0 text-white text-shadow h1">
                  {context.mainrestdata.restdata.name}
                </h1>
              </div>
            ) : null}
            {subRestData
              ? subRestData.map((val, i) => {
                  return (
                    <div
                      onClick={() => {
                        setState({ ...state, subRest: false });
                        context.selectRestaurant(val.restaurant_id);
                        toast.success("Switched to " + val.name);
                      }}
                      key={i}
                      className={`${
                        context.selectedRestID === val.restaurant_id
                          ? "border-warning"
                          : ""
                      } rounded p-4  mb-3 text-center bg-default `}
                      style={{
                        backgroundImage: `url(${val.image})`,
                        backgroundSize: "cover",
                        backgroundPosition: "10px",
                        border: "4px solid",
                      }}
                    >
                      <h1 className="m-0 text-white text-shadow h1">
                        {val.name}
                      </h1>
                    </div>
                  );
                })
              : null}
          </ModalBody>
        </Modal>
        <div className="row pl-3">
          {back ? (
            <Icon
              size={30}
              icon={ic_keyboard_backspace}
              style={{ color: "white" }}
              onClick={back}
            />
          ) : (
            <Icon
              size={30}
              icon={ic_menu}
              style={{ color: "white" }}
              onClick={() => {
                context.setContextState({
                  sideNav: !context.sideNav,
                });
              }}
            />
          )}

          <h5 className="h1 m-0 text-white pl-5">{pageName}</h5>
        </div>
        {
          context && context.restdata && context.restdata.show_lock_screen ? <Icon
          icon={ic_lock}
          size={40}
          className="text-light"
          onClick={() =>
            context.setContextState({
              selectedStaff: null,
              staff_is_authorized: false,
            })
          }
        />:null
        }
        <div>
          {subRestData && subRestData.length === 0 ? null : (
            <Icon
              icon={ic_store}
              size={40}
              style={{ color: "white", marginRight: "6vw" }}
              onClick={() =>
                setState({
                  ...state,
                  subRest: true,
                })
              }
            />
          )}
          {/* <span
            className="navbar-text"
            onClick={() => setNotification(!notification)}
          >
            <Icon icon={ic_notifications} size={40} />
            <span
              class="bg-danger text-white badge badge-pill badge-danger"
              style={{
                display: "revert",
                left: "62px",
                top: "-19px",
              }}
            >
              {context.paymentUpdates && context.paymentUpdates.length > 0
                ? context.paymentUpdates.length
                : null}
            </span>
          </span> */}
          {notification ? (
            <div
              className="_notification navbar-vertical fixed-right navbar-dark bg-default hide p-2"
              expand="lg"
              id="sidenav-main"
              style={{
                zIndex: "99",
                position: "fixed",
                width: "43%",
                right: 0,
                height: "100%",
                top: 0,
              }}
            >
              <div className="d-flex justify-content-end">
                {/* <Icon icon={ic_notifications} size={40} className='text-white' onClick={() => setNotification(!notification)} /> */}
                <Button
                  color="danger"
                  onClick={() => setNotification(!notification)}
                >
                  Close
                </Button>
                <hr />
              </div>

              {/* <Notifications /> */}
            </div>
          ) : null}
        </div>
      </nav>
      {stickyComponent ? (
        <div id="sticky" className="bg-primary p-2">
          {stickyComponent}
        </div>
      ) : null}
    </div>
  );
});
