import React from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components

// reactstrap components
import {
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Navbar,
  Nav,
} from "reactstrap";
import EachNav from "./EachNav";
import { user } from "react-icons-kit/icomoon/user";
import { ic_restaurant } from "react-icons-kit/md/ic_restaurant";
import { ic_event_seat } from "react-icons-kit/md/ic_event_seat";
import { ic_local_offer } from "react-icons-kit/md/ic_local_offer";
import { withTheme } from "../../contexts/AppContext";
import { ic_date_range } from "react-icons-kit/md/ic_date_range";
import { ic_settings_power } from "react-icons-kit/md/ic_settings_power";
import { calendar } from "react-icons-kit/icomoon/calendar";
import { ic_list } from "react-icons-kit/md/ic_list";
import { ic_people } from "react-icons-kit/md/ic_people";
import { ic_access_time } from "react-icons-kit/md/ic_access_time";
import { history } from "react-icons-kit/icomoon/history";
import { ic_add_shopping_cart } from "react-icons-kit/md/ic_add_shopping_cart";
import { creditCard } from "react-icons-kit/icomoon/creditCard";

class Sidebar extends React.PureComponent {
  state = {
    hideLinks: false,
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      !this.props.context.selectedStaff ||
      (this.props.context.selectedStaff &&
        window.server_roles[this.props.context.selectedStaff.server_role] >= 5)
    ) {
      this.setState({ hideLinks: false });
    } else {
      this.setState({ hideLinks: true });
    }
  }

  render() {
    const { logo, context } = this.props;
    const { selectedRestData } = context;
    // const { type } = selectedRestData.restdata || null;
    // const isHomeChef = type === 'homechef';
    // console.log('SIDE_NAV ', context.selectedRestData.restdata)
    let navbarBrandProps;
    if (logo && logo.innerLink) {
      navbarBrandProps = {
        to: logo.innerLink,
        tag: Link,
      };
    } else if (logo && logo.outterLink) {
      navbarBrandProps = {
        href: logo.outterLink,
        target: "_blank",
      };
    }
    if (context.sideNav) {
      return (
        <Navbar
          className="navbar-vertical fixed-left navbar-dark bg-default show"
          expand="md"
          id="sidenav-main"
          style={{
            zIndex: "99",
            position: "fixed",
            width: "70%",
            top: 0,
            bottom: 0,
            overflowY: "scroll",
          }}
        >
          <div>
            <Nav className="align-items-center d-md-none">
              <UncontrolledDropdown nav></UncontrolledDropdown>
              <UncontrolledDropdown nav>
                <DropdownToggle nav>
                  <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      <img
                        alt="..."
                        src={require("../../assets/img/theme/team-1-800x800.jpg")}
                      />
                    </span>
                  </Media>
                </DropdownToggle>
              </UncontrolledDropdown>
            </Nav>
            {/* Collapse */}
            <div navbar>
              {/* Collapse header */}
              {/* <div className="navbar-collapse-header d-md-none">
                                <Row>
                                    {logo ? (
                                        <Col className="collapse-brand" xs="6">
                                            {logo.innerLink ? (
                                                <Link to={logo.innerLink}>
                                                    <img alt={logo.imgAlt} src={logo.imgSrc} />
                                                </Link>
                                            ) : (
                                                <a href={logo.outterLink}>
                                                    <img alt={logo.imgAlt} src={logo.imgSrc} />
                                                </a>
                                            )}
                                        </Col>
                                    ) : null}
                                    <Col className="collapse-close" xs="6">
                                        <button
                                            className="navbar-toggler"
                                            type="button"
                                        >
                                            <span />
                                            <span />
                                        </button>
                                    </Col>
                                </Row>
                            </div> */}
              {/* <Nav navbar>{this.createLinks(routes)}</Nav> */}
              <hr className="my-3" />
              {/* <h6 className="navbar-heading text-muted">Documentation</h6> */}
              {selectedRestData ? (
                <Nav
                  className="mb-md-3"
                  style={{ paddingTop: "20%", paddingBottom: "20%" }}
                  navbar
                >
                  {this.state.hideLinks ? null : (
                    <>
                      <EachNav to="/profile" title="Profile" icon={user} />
                      <EachNav to="/menu" title="Menu" icon={ic_list} />
                      <EachNav to="/accounts" title="Accounts" icon={history} />
                      {selectedRestData.restdata.type !== "homechef" ? (
                        <EachNav
                          to="/reservations"
                          title="Reservations"
                          icon={ic_access_time}
                        />
                      ) : null}

                      {selectedRestData.restdata.type !== "homechef" ? (
                        <EachNav
                          to="/tables"
                          title="Tables"
                          icon={ic_event_seat}
                        />
                      ) : null}
                      {selectedRestData.restdata.type === "homechef" ? (
                        <EachNav
                          to="/slot"
                          title="Delivery Slot"
                          icon={calendar}
                        />
                      ) : null}

                      <EachNav
                        to="/feedback"
                        title="Feedback"
                        icon={ic_add_shopping_cart}
                      />
                    </>
                  )}
                  {!this.props.context.selectedStaff ||
                  (this.props.context.selectedStaff &&
                    !(
                      this.props.context.selectedStaff.server_role === "chef" ||
                      this.props.context.selectedStaff.server_role ===
                        "bartender"
                    )) ? (
                    <EachNav
                      to="/orders"
                      title="Orders"
                      icon={ic_add_shopping_cart}
                    />
                  ) : null}

                  {selectedRestData.restdata.type !== "homechef" &&
                  (!this.props.context.selectedStaff ||
                    (this.props.context.selectedStaff &&
                      (this.props.context.selectedStaff.server_role ===
                        "chef" ||
                        this.props.context.selectedStaff.server_role ===
                          "bartender" ||
                        window.server_roles[
                          this.props.context.selectedStaff.server_role
                        ] >= 5))) ? (
                    <EachNav
                      to="/kitchen"
                      title="Kitchen"
                      icon={ic_restaurant}
                    />
                  ) : null}

                  {selectedRestData.restdata.loyalty ? (
                    <EachNav to="/loyalty" title="Loyalty" icon={creditCard} />
                  ) : null}

                  {!this.props.context.selectedStaff ||
                  (this.props.context.selectedStaff &&
                    !(
                      this.props.context.selectedStaff.server_role === "chef" ||
                      this.props.context.selectedStaff.server_role ===
                        "bartender"
                    )) ? (
                    <EachNav
                      to="/history"
                      title="Order History"
                      icon={ic_date_range}
                    />
                  ) : null}
                  {/* <EachNav to='/offers' title='Offers' icon={ic_local_offer} /> */}
                  {/* {
                                            selectedRestData.restdata.type !== "homechef" ?
                                            <EachNav to='/events' title='Events' icon={ic_date_range} />:null
                                        } */}
                  {/* {
                                            selectedRestData.restdata.type !== "homechef" ? <EachNav to='/staff' title='Staff' icon={ic_people} /> : null
                                        } */}
                  <div onClick={context.logout}>
                    <EachNav to="/#" title="Logout" icon={ic_settings_power} />
                  </div>
                </Nav>
              ) : null}
            </div>
          </div>
        </Navbar>
      );
    } else {
      return null;
    }
  }
}

export default withTheme(Sidebar);
