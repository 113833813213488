import React from 'react'
import Header from '../Header/Header';
import Sidenav from '../SideNav/SideNav'

export default React.memo(function Layout({
    children,
    pageName,
    stickyComponent,
    back
}) {
    return (
        <>
            <Header
                pageName={pageName}
                stickyComponent={stickyComponent}
                back={back}
            />
            <Sidenav />
            {/* <div style={{ paddingTop: '6%', paddingBottom: '6%', backgroundColor: '#e3e6ea' }}> */}
            <div className='layout'>
                {children}
            </div>
        </>
    )
});
