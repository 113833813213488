import React, { Fragment, useState } from "react";
import {
  Row,
  Col,
  Button,
  ButtonGroup,
  Modal,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Badge,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input
} from "reactstrap";
import Select from "react-select";
import EachTableOrderRow from "../../dashboardComponents/orders/EachTableOrderRow";
import { Redirect } from "react-router";
import { FieldGroup } from "../../helpers/FieldGroup";
import AppLoading from "../../components/AppLoading";
// import _Badge from "../../components/_Badge";
//import Button from "../../components/Button";
//import CheckMarkIcon from "../../img/checkmark.png";
//import MultiplyIcon from "../../img/multiply.png";
//import '../../dashboardComponents/OrderHistory/OrderHistory.css';
import { displayDate } from "../../helpers/DateFunctions";
import moment from "moment";
import { Marker } from "react-google-maps";

const EachOrderView = ({ parent, orderdata }) => {
  var orderRows = [];
  let cancelledOrderRows = [];
  var subtotal = 0.0;
  var tax = 0.0;
  var cgst = 0.0;
  var sgst = 0.0;
  let vat = 0.0;
  var service_fee = 0.0;
  let kumba_fee = 0.0;
  var total = 0.0;
  let pay_total = 0.0;
  let delivery_charge = 0.0;

  var checkoutStatus = true;

  var hours = 0;
  var minutes = 0;
  var seconds = 0;
  var days = 0;

  let primaryColor = parent.props.context.primaryColor;
  let successColor = parent.props.context.successColor;
  let dangerColor = parent.props.context.dangerColor;

  let [showReadyForPickupModal, setPickupModal] = useState(false);
  let [modalType, setModalType] = useState(null);

  let [showOrderReadyModal, setOrderReadyModal] = useState(false);

  let [adjustmentModal, setAdjustmentModal] = useState(false);
  let [adjustmentAmount, setAdjustmentAmount] = useState(0);
  let [adjustmentReason, setAdjustmentReason] = useState('');

  //   let orderdata = orderdata;
  let order_from = parent.props.from;
  let formatteddate = null;

  let waiting_orders = [];

  if (orderdata && orderdata.orders && orderdata.orders.length > 0) {
    orderdata.orders
      .sort((a, b) => {
        return new Date(a.createdAt) - new Date(b.createdAt);
      })
      .forEach((order, index) => {
        if (
          order.status !== "unconfirmed" &&
          order.status !== "rejected" &&
          order.status !== "cancelled"
        ) {
          orderRows.push(
            <EachTableOrderRow
              key={index}
              index={index}
              orderdata={order}
              token={parent.props.token}
              getOrder={parent.getOrder.bind(parent)}
              markReject={parent.markReject.bind(parent)}
            />
          );
          //subtotal += order.item_total * order.quantity;
          if (order.status === "accepted") {
            checkoutStatus = false;
          } else if (order.status === "waiting") {
            waiting_orders.push(order);
          }
        }

        if(order.status === "cancelled") {
            cancelledOrderRows.push(
                <EachTableOrderRow
                    key={index}
                    index={index}
                    orderdata={order}
                    token={parent.props.token}
                    getOrder={parent.getOrder.bind(parent)}
                    markReject={parent.markReject.bind(parent)}
                />
            )
        }
      });

    subtotal = orderdata.order_subtotal;

    kumba_fee = orderdata.kumbaAmount;
    tax = orderdata.tax_total;
    pay_total = orderdata.order_bill - orderdata.discount;
    delivery_charge = orderdata.delivery_charge || 0.0;
    total = orderdata.order_bill;

    var d1 = parent.state.now;
    var d2 = new Date(orderdata.createdAt);
    var time = (d1.getTime() - d2.getTime()) / 1000;

    var mintime = parseInt(time / 60);
    seconds = parseInt(time % 60);

    var hourtime = parseInt(mintime / 60);
    minutes = parseInt(mintime % 60);

    days = parseInt(hourtime / 24);
    hours = parseInt(hourtime % 24);

    var date = d2.toString();

    formatteddate = date.substring(0, date.indexOf(" GMT"));
  }

  // parent.props.context.paymentUpdates &&
  // parent.props.context.paymentUpdates[0].table_name;
  // console.log(parent.props.context && parent.props.context);
  // console.log(parent.props.context.restdata);

  // parent.props.context.restdata.menu_items.forEach((item, index) => {
  //     console.log(item.name);

  // })
  //console.log(orderdata);

  if (parent.state.goNewOrder) {
    return <Redirect to="/neworder" from="/orders" />;
  } else if (orderdata && orderdata.order_id) {
    console.log({ index: parent.props.index });
    return (
        <Fragment>
            {
            parent.props.type === "map" ?
            orderdata.delivery_address && orderdata.order_status !== "delivered" ?
            <Marker key={"eachcodemarker" + orderdata.order_id} position={{ lat: parseFloat(orderdata.delivery_address.lat), lng: parseFloat(orderdata.delivery_address.long) }} label={"#" + orderdata.internal_order_num} /> : null :
            <tr key={orderdata.order_id + "-row"} onClick={parent.openOrderModal.bind(parent)} style={{backgroundColor : orderdata.order_status === "delivered" ? "wheat" : orderdata.order_status === "awaiting_payment" ? "yellow" : orderdata.order_ready ? "lightgreen" : null}}>
                                <td>
                                    {orderdata.internal_order_num}
                                </td>
                                <td>
                                    {orderdata.user ? orderdata.user.first_name + (orderdata.user.last_name ? " " + orderdata.user.last_name : "") : ""}
                                </td>
                                <td>
                                    {orderdata.user ? orderdata.user.phone : null}
                                </td>
                                <td>
                                    {orderdata.payment_mode + (orderdata.payment_info ? " (" + orderdata.payment_info  + ")" : "") + (orderdata.order_ready ? "( packed )" : "")}
                                </td>
                                <td>
                                    {orderdata.customer_preferred_time ? (new Date(orderdata.customer_preferred_time)).toLocaleString("en-IN", {weekday : "short", year : "2-digit", month : "2-digit", day : "2-digit", hour : "2-digit", minute : "2-digit", hour12 : true}) : ""}
                                </td>
                                <td>
                                    {window.rupeeSym + orderdata.order_bill.toFixed(2)}
                                </td>
                                <td>
                                    {orderdata.delivery_address ? (orderdata.delivery_address.landmark ? orderdata.delivery_address.landmark + ", " : "") + (orderdata.delivery_address.apt ? orderdata.delivery_address.apt + "," : "") + (orderdata.delivery_address.building ? orderdata.delivery_address.building + ", " : "") + orderdata.delivery_address.street + (orderdata.delivery_address.note ? orderdata.delivery_address.note + " " : "") : "Not Available"}
                                </td>
                                <td>
                                    {/* {orderdata.delivery_address ? <a href={"https://www.google.com/maps/search/?api=1&query=" + orderdata.delivery_address.lat + "%2C" + orderdata.delivery_address.long}>Link</a> : null} */}
                                     {orderdata.delivery_address ? "https://www.google.com/maps/search/?api=1&query=" + orderdata.delivery_address.lat + "%2C" + orderdata.delivery_address.long : null}
                                </td>
                                
                                
                                <td>
                                    {orderdata.order_id}
                                </td>
                            </tr>}



            <Modal isOpen={parent.state.showOrderModal}
                size="lg" 
                style={{ 
                            // maxHeight : "60%",
                            // maxWidth : "90%",
                            top : "20px",
                            //margin : 0,
                            //padding:"0px",
                            backgroundColor : "#ffffff",
                    }} 
                aria-labelledby="contained-modal-title-lg"
                role="dialog"
                autoFocus={true}
            >
                <ModalHeader>
                    <Row>
                        <Col>
                            <Button style={{backgroundColor: "#1d3557", color: 'white'}} onClick={parent.closeOrderModal.bind(parent)}>
                                Close
                            </Button>
                            
                        </Col>
                        <Col>
                            <Button style={{backgroundColor: "#1d3557", color: 'white'}} onClick={() => setOrderReadyModal(true)}>
                                Order Packed
                            </Button>
                        </Col>
                        
                    </Row>
                </ModalHeader>
                <ModalBody>
                    <Row>
                    <Col sm={6} xs={12} style={{ padding: "0" }}>
                        <div className="item-detail">
                                <ListGroup >
                                <ListGroupItem className="bg-secondary" style={{ textAlign: "center" }}>
                                <Row >
                                    <Col md={3} xs={3}>
                                    Qty
                                    </Col>
                                    <Col md={5} xs={5}>
                                    {window.itemTerm}
                                    </Col>
                                    <Col md={3} xs={3}>
                                    Price
                                    </Col>
                                </Row>
                                </ListGroupItem>
                            </ListGroup>

                            <div className="item-list">
                                {orderRows}
                            </div>

                            <h4>Cancelled Items</h4>
                            <div className="item-list">
                                {cancelledOrderRows}
                            </div>

                            <Row>
                                <Col xs={6}>
                                    <h5>Sub - Total:</h5>
                                </Col>

                                <Col xs={6} className="text-right">
                                    {window.rupeeSym + (Math.round(subtotal * 100) / 100).toFixed(2)}
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={6}>
                                    <h5>{"Tax"}</h5>
                                </Col>

                                <Col xs={6} className="text-right">
                                    {window.rupeeSym + (Math.round(tax * 100) / 100).toFixed(2)}
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={6}>
                                    <h5>Discount</h5>
                                </Col>
                                <Col xs={6} className="text-right">
                                    
                                    {window.rupeeSym +
                                        Math.round(
                                            orderdata.discount
                                        ).toFixed(2)}
                                </Col>
                            </Row>
                            {orderdata.adjustment_amount !== 0 ?
                            <>
                            <Row>
                                <Col xs={6}>
                                    <h5>{"Adjustments : " + orderdata.adjustment_reason}</h5>
                                </Col>
                                <Col xs={6} className="text-right">
                                    
                                    {window.rupeeSym +
                                        Math.round(
                                            orderdata.adjustment_amount
                                        ).toFixed(2)}
                                </Col>
                            </Row>
                            </> : null}
                            <Row>
                                <Col xs={6}>
                                    <h4>Total</h4>
                                </Col>
                                <Col xs={6} className="text-right">
                                    <h4>{window.rupeeSym + (pay_total - delivery_charge).toFixed(2)}</h4>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Button size="xs" style={{backgroundColor: "#1d3557", color: 'white'}} onClick={() => setAdjustmentModal(true)}>
                                        Adjustment
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col sm={6} xs={12}>
                                <div className="list-wrapper">
                                <ListGroup>
                                    <ListGroupItem>
                                    <div>
                                        <h4 style={{ fontWeight: "400" }}>Contact:</h4>
                                    </div>
                                    <div>
                                        {orderdata.allusers && orderdata.allusers.length>0 ? orderdata.allusers.map(x => 
                                        <h4>
                                            {x.first_name + ", " + x.phone}
                                        </h4>) : "NA"}
                                    </div>
                                    </ListGroupItem>
                                    <ListGroupItem>
                                    <Row>
                                        <Col sm={6}>
                                        <div>
                                            <h4 style={{ fontWeight: "400" }}>Order Time:</h4>
                                        </div>
                                        <div>
                                            <h4>{formatteddate}</h4>
                                        </div>
                                        </Col>
                                    </Row>
                                    </ListGroupItem>
                                    <ListGroupItem>
                                    <div>
                                        <h4 style={{ fontWeight: "400" }}>Order ID:</h4>
                                    </div>
                                    <div>
                                        <h4>{`#${orderdata.order_id}`}</h4>
                                    </div>
                                    </ListGroupItem>
                                    <ListGroupItem>
                                    <div>
                                        <h4 style={{ fontWeight: "400" }}>Payment Mode:</h4>
                                    </div>
                                    <div>
                                        <h4>{orderdata.payment_mode}</h4>
                                    </div>
                                    </ListGroupItem>
                                    {orderdata.order_type === "delivery" ? 
                                        <ListGroupItem>
                                        <div>
                                            <h4 style={{ fontWeight: "400" }}>Delivery Address:</h4>
                                        </div>
                                        <div>
                                            <h4>{orderdata.delivery_address ? (orderdata.delivery_address.landmark ? orderdata.delivery_address.landmark + ", " : "") + (orderdata.delivery_address.apt ? orderdata.delivery_address.apt + "," : "") + (orderdata.delivery_address.building ? orderdata.delivery_address.building + ", " : "") + orderdata.delivery_address.street + (orderdata.delivery_address.note ? orderdata.delivery_address.note + " " : "") : "Not Available"}</h4>
                                        </div>
                                        </ListGroupItem> : null
                                    }
                                </ListGroup>
                                </div>
                            </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    {parent.props.paymentUpdate || (!parent.props.paymentUpdate && orderdata.order_status !== "finished") || order_from === "orderhistory" ? 
                <div className="row justify-content-center mb-3">
                    {/* <div className="mt-3 col-6 row justify-content-center" >
                        <Button style={{backgroundColor: primaryColor,alignSelf:'center', color: 'white'}} onClick={parent.openOrderModal.bind(parent)}>
                            View Details
                        </Button>
                    </div> */}
                    {order_from === "orderhistory" ? null : orderdata && orderdata.order_type === "delivery" ?
                        <>
                            <Row>
                                <Col>
                                    <Button style={{backgroundColor: "#1d3557", color: 'white'}} onClick={() => setAdjustmentModal(true)}>
                                        Adjustment
                                    </Button>
                                </Col>
                                <Col>
                                    <Button style={{backgroundColor: primaryColor, color: 'white'}} onClick={parent.openCheckoutModal.bind(parent)}>
                                        {"Finish Order"}
                                    </Button>
                                </Col>
                            </Row>
                            {/* {orderdata.handle_delivery ? 
                                (
                                    orderdata.order_status === "accepted"
                                    ? (
                                        <div className="col-6 mt-3 row justify-content-center">
                                            <Button style={{backgroundColor: primaryColor, color: 'white'}} onClick={parent.openDispatchModal.bind(parent)}>
                                                {"Request Delivery"}
                                            </Button>
                                        </div>
                                    ) : (orderdata.order_status === "delivery-created" ||
                                orderdata.order_status === "delivery-accepted" ?
                                <div className="col-6 mt-3 row justify-content-center">
                                    <Button style={{backgroundColor: primaryColor, color: 'white'}} onClick={parent.openDispatchModal.bind(parent)}>
                                        {"Cancel Delivery"}
                                    </Button>
                                </div> : null)
                                ) : (
                                    <div className="col-6 mt-3 row justify-content-center">
                                        <Button style={{backgroundColor: primaryColor, color: 'white'}} onClick={parent.openCheckoutModal.bind(parent)}>
                                            {"Ready for Delivery"}
                                        </Button>
                                    </div>
                                )} */}
                        </>
                         : 
                        orderdata.order_type === "takeaway" ? (
                            orderdata.order_status === "received" ? 
                                <div className="col-6 mt-3 row justify-content-center">
                                    <Button style={{backgroundColor: primaryColor, color: 'white'}} onClick={() => {setPickupModal(true); setModalType("prepare order")}}>
                                        {"Prepare Order"}
                                    </Button>
                                </div> 
                            : 
                            orderdata.order_status === "accepted" ? 
                                <div className="col-6 mt-3 row justify-content-center">
                                    <Button style={{backgroundColor: primaryColor, color: 'white'}} onClick={() => {setPickupModal(true); setModalType("ready for pickup")}}>
                                        {"Ready for Pickup"}
                                    </Button>
                                </div> 
                            : orderdata.order_status === "ready-for-pickup" ? 
                                <div className="col-6 mt-3 row justify-content-center">
                                    <Button style={{backgroundColor: primaryColor, color: 'white'}} onClick={parent.openCheckoutModal.bind(parent)}>
                                        {"Picked up Order"}
                                    </Button>
                                </div>
                            : null
                        ) :
                        <div className="col-6 mt-3 row justify-content-center">
                            <Button style={{backgroundColor: primaryColor, color: 'white'}} onClick={parent.openCheckoutModal.bind(parent)}>
                                {"Checkout Order"}
                                </Button>
                        </div>
                    }
                </div> : null
                }
                </ModalFooter>
            </Modal>

        {parent.state.showCheckoutModal &&
        <Modal
          isOpen={parent.state.showCheckoutModal}
          size="lg"
          style={{
            maxHeight: "60%",
            width: "90%",
            top: "20px",
            left: "5%",
            backgroundColor: "#ffffff",
          }}
          aria-labelledby="contained-modal-title-lg"
          role="dialog"
          autoFocus={true}
        >
          <ModalHeader>
            <h4>{"Checkout"}</h4>
          </ModalHeader>
          <ModalBody style={{ textAlign: "center" }}>
            {checkoutStatus ? (
              <p className="text-danger">
                {
                  "NOTE : There are pending orders that haven't been served to the customer yet."
                }
              </p>
            ) : null}
            <p>
              {"Do you want to checkout the order #" +
                parent.props.orderID +
                " ?"}
            </p>
            {orderdata.razorpay_payment_id ? 
                <Button
                    color="success"
                    onClick={() => parent.finalizeOrder(null)}
                >
                    Yes
                </Button> : 
            <div className="row justify-content-center">
              <Button
                className="col-xs-12 mt-1"
                color="success"
                onClick={() => parent.finalizeOrder("cash")}
              >
                Paid by Cash
              </Button>
              <Button
                className="col-xs-12 mt-1"
                color="success"
                onClick={() => parent.finalizeOrder("card")}
              >
                Paid by Card
              </Button>
              <Button
                color="success"
                className="col-xs-12 mt-1"
                onClick={() => parent.finalizeOrder("offline")}
              >
                Paid by Other
              </Button>
              </div>
            }
            <hr />
            <Button
              className="col-lg-6 col-12"
              color="danger"
              onClick={parent.closeCheckoutModal.bind(parent)}
            >
              Close
            </Button>
          </ModalBody>
        </Modal>}

        <Modal
          isOpen={parent.state.showDispatchModal}
          size="lg"
          style={{
            maxHeight: "60%",
            maxWidth: "90%",
            width: "60%",
            top: "20px",
            backgroundColor: "#ffffff",
          }}
          aria-labelledby="contained-modal-title-lg"
          role="dialog"
          autoFocus={true}
        >
          <ModalHeader>
            <h4>{"Dispatch"}</h4>
          </ModalHeader>
          <ModalBody style={{ textAlign: "center" }}>
            <p>
              {"Do you want to dispatch the order #" +
                parent.props.orderID +
                " for delivery ?"}
            </p>
            {orderdata.payment_id ? (
              <Button
                color="success"
                onClick={() => parent.dispatchOrder("kumba")}
              >
                Yes
              </Button>
            ) : (
              <Fragment>
                <Button
                  color="success"
                  onClick={() => parent.dispatchOrder("cash")}
                >
                  Paid by Cash
                </Button>
                <Button
                  color="success"
                  onClick={() => parent.dispatchOrder("card")}
                >
                  Paid by Card
                </Button>
                <Button
                  color="success"
                  onClick={() => parent.dispatchOrder("offline")}
                >
                  Paid by Other
                </Button>
              </Fragment>
            )}
            <Button
              color="danger"
              onClick={parent.closeDispatchModal.bind(parent)}
            >
              Close
            </Button>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={parent.state.showCancelDeliveryModal}
          size="lg"
          style={{
            maxHeight: "60%",
            maxWidth: "90%",
            width: "60%",
            top: "20px",
            backgroundColor: "#ffffff",
          }}
          aria-labelledby="contained-modal-title-lg"
          role="dialog"
          autoFocus={true}
        >
          <ModalHeader>
            <h4>{"Cancel Delivery"}</h4>
          </ModalHeader>
          <ModalBody style={{ textAlign: "center" }}>
            <p>
              {"Do you want to cancel the delivery for order #" +
                parent.props.orderID +
                " ? "}
            </p>
            <Button
              color="success"
              onClick={() => parent.cancelDeliveryOrder()}
            >
              Yes
            </Button>
            <Button
              color="danger"
              onClick={parent.closeCancelDeliveryModal.bind(parent)}
            >
              No
            </Button>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={showOrderReadyModal}
          size="lg"
          style={{
            maxHeight: "60%",
            maxWidth: "90%",
            width: "60%",
            top: "20px",
            backgroundColor: "#ffffff",
          }}
          aria-labelledby="contained-modal-title-lg"
          role="dialog"
          autoFocus={true}
        >
          <ModalHeader>
            <h4>{"Order Packed?"}</h4>
          </ModalHeader>
          <ModalBody style={{ textAlign: "center" }}>
            <p>
              {"Do you want to mark the order as packed for order #" +
                parent.props.orderID +
                " ? "}
            </p>
            <Button
              color="success"
              onClick={async () => {  await parent.markOrderReady(); setOrderReadyModal(false); } }
            >
              Yes
            </Button>
            <Button
              color="danger"
              onClick={() => setOrderReadyModal(false)}
            >
              No
            </Button>
          </ModalBody>
        </Modal>

          {adjustmentModal &&
        <Modal
          isOpen={adjustmentModal}
          size="lg"
          style={{
            maxHeight: "60%",
            maxWidth: "90%",
            width: "60%",
            top: "20px",
            backgroundColor: "#ffffff",
          }}
          aria-labelledby="contained-modal-title-lg"
          role="dialog"
          autoFocus={true}
        >
          <ModalHeader>
            <h4>{"Adjust Order Bill"}</h4>
          </ModalHeader>
          <ModalBody style={{ textAlign: "center" }}>
            <FormGroup className="m-0">
                <InputGroup>
                <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                    Amount
                    </InputGroupText>
                </InputGroupAddon>
                <Input
                    placeholder={"adjustment amount"}
                    type="number"
                    name="adjustment_amount"
                    value={adjustmentAmount}
                    onChange={(e) => setAdjustmentAmount(e.target.value)}
                />
                </InputGroup>
            </FormGroup>
            <FormGroup className="m-0 mt-4">
                <InputGroup>
                <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                    Reason
                    </InputGroupText>
                </InputGroupAddon>
                <Input
                    placeholder={"Reason"}
                    type="text"
                    name="adjustment_reason"
                    value={adjustmentReason}
                    onChange={(e) => setAdjustmentReason(e.target.value)}
                />
                </InputGroup>
            </FormGroup>
            <Button
              color="success"
              onClick={() => { parent.makeAdjustments(adjustmentAmount, adjustmentReason); setAdjustmentModal(false); } }
            >
              Apply
            </Button>
            <Button
              color="danger"
              onClick={() => setAdjustmentModal(false)}
            >
              Cancel
            </Button>
          </ModalBody>
        </Modal>}

        <Modal
          isOpen={showReadyForPickupModal}
          size="lg"
          style={{
            maxHeight: "60%",
            maxWidth: "90%",
            width: "60%",
            top: "20px",
            backgroundColor: "#ffffff",
          }}
          aria-labelledby="contained-modal-title-lg"
          role="dialog"
          autoFocus={true}
        >
          <ModalHeader>
            <h4>{modalType ? modalType.toUpperCase() : ""}</h4>
          </ModalHeader>
          <ModalBody style={{ textAlign: "center" }}>
            <p>
              {"Do you want to update for order #" +
                parent.props.orderID +
                " ? "}
            </p>
            <Button
              color="success"
              onClick={() => {
                if (modalType) {
                  if (modalType === "prepare order") {
                    parent.markPrepareOrder();
                  } else if (modalType === "ready for pickup") {
                    parent.markReadyForPickup();
                  }
                }
                setPickupModal(false);
                setModalType(null);
              }}
            >
              Yes
            </Button>
            <Button
              color="danger"
              onClick={() => {
                setPickupModal(false);
                setModalType(null);
              }}
            >
              No
            </Button>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={parent.state.showTableChangeModal}
          size="lg"
          style={{
            maxHeight: "60%",
            maxWidth: "90%",
            top: "20px",
            backgroundColor: "#ffffff",
          }}
          aria-labelledby="contained-modal-title-lg"
          role="dialog"
          autoFocus={true}
        >
          <ModalBody>
            <Select
              name="newTableID"
              value={parent.tableOptions
                .filter((x) => x.value === parent.state.newTableID)
                .pop()}
              onChange={parent.handleTableSelect.bind(parent)}
              options={parent.tableOptions}
              placeholder="Select New Table : "
            />
            <Button
              onClick={parent.changeTable.bind(parent)}
              className="mt-2 col-xs-6 col-md-3 col-6 btn btn-default"
            >
              Change Table
            </Button>
            <Button
              className="mt-2 col-md-3 col-5 col-xs-6 btn-danger"
              onClick={() => {
                parent.setState({ showTableChangeModal: false });
              }}
            >
              Cancel
            </Button>
          </ModalBody>
        </Modal>
      </Fragment>
    );
  } else {
    return <AppLoading />;
  }
};

export default EachOrderView;
