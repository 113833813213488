import axios from 'axios'
import React, { useCallback, useEffect } from 'react'
import { useState } from 'react'
import { Spinner } from 'react-activity'
import {
	Col,
	ListGroup,
	ListGroupItem,
	Modal,
	ModalBody,
	ModalHeader,
	Row,
} from 'reactstrap'
import { withTheme } from '../../contexts/AppContext'

const managerAccessLevel = window?.server_roles?.supervisor

function OrderHistoryDetails({ data, onClose, context }) {
	const [loading, setLoading] = useState(true)
	const [orderData, setOrderData] = useState(null)
	const [userInfo, setUserInfo] = useState(null)


	const getUserInfo = useCallback(async () => {
		try {
			let url =
				window.kumbaURL +
				'/mainorders/getuserorders/' +
				orderData?.user?.user_id +
				'/' +
				context.restID
			let res = await axios({
				url: url,
				headers: { 'foodbots-header': context.token },
			})
			// console.log('USER DATA --', res.data)
			setLoading(false)
			setUserInfo(res.data)
			return Promise.resolve(res.data)
		} catch (error) {
			setLoading(false)
			return Promise.reject(error)
		}
	}, [context.restID, context.token, orderData?.user?.user_id])

	const getOrderData = useCallback(async () => {
		try {
			let url = window.kumbaURL + '/mainorders/sorted/' + data?.order_id
			let header = { 'foodbots-header': context?.token }

			let response = await axios({
				url: url,
				headers: header,
				method: 'GET',
			})
			setOrderData(response.data)
			getUserInfo()
		} catch (error) {
			setLoading(false)
			return Promise.reject(error)
		}
	}, [context?.token, data?.order_id, getUserInfo])

	useEffect(() => {
		getOrderData()
	}, [getOrderData])

	return (
		<Modal
			isOpen
			size="lg"
			style={{
				maxHeight: '60%',
				maxWidth: '90%',
				top: '20px',
				backgroundColor: '#ffffff',
			}}
			aria-labelledby="contained-modal-title-lg"
			role="dialog"
			autoFocus={true}
		>
			<ModalHeader>
				<button className="btn text-danger btn-lg" onClick={onClose}>
					Close
				</button>
			</ModalHeader>
			<ModalBody>
				{loading ? (
					<div
						style={{ height: '70vh' }}
						className="d-flex justify-content-center align-items-center"
					>
						<div className="text-center">
							<Spinner /> <h5>Just a moment..</h5>
						</div>
					</div>
				) : (
					<Row>
						<Col sm={6} xs={12} style={{ padding: '0' }}>
							<div className="card">
								<div className="card-body p-1">
									<table class="table table-striped">
										<thead>
											<tr>
												<th scope="col">Qty</th>
												<th scope="col">Item</th>
												<th scope="col">Price</th>
											</tr>
										</thead>
										<tbody>
											{orderData?.orders &&
												orderData?.orders
													?.sort((a, b) => {
														return new Date(a.createdAt) - new Date(b.createdAt)
													})
													?.map((order, index) => {
														console.log('ORDER - ', order)
														return (
															<tr key={`Each-order-${index}`}>
																<th scope="row">{order?.quantity}</th>
																<td>{order?.item_id?.name}</td>
																<td>
																	{window.rupeeSym +
																		parseFloat(order?.item_total).toFixed(2)}
																</td>
															</tr>
														)
													})}
										</tbody>
									</table>
								</div>
							</div>
							<br />
							<Row>
								<Col xs={6}>
									<h5>Sub - Total:</h5>
								</Col>

								<Col xs={6} className="text-right">
									{window.rupeeSym +
										(Math.round(orderData?.order_subtotal * 100) / 100).toFixed(
											2
										)}
								</Col>
							</Row>
							<Row>
								<Col xs={6}>
									<h5>{'Tax'}</h5>
								</Col>

								<Col xs={6} className="text-right">
									{window.rupeeSym +
										(Math.round(orderData?.tax_total * 100) / 100).toFixed(2)}
								</Col>
							</Row>

							{orderData && orderData.discount ? (
								<Row>
									<Col xs={6}>
										<h5>Discount</h5>
									</Col>
									<Col xs={6} className="text-right">
										{window.rupeeSym +
											Math.round(orderData?.discount).toFixed(2)}
									</Col>
								</Row>
							) : null}

							<Row>
								<Col xs={6}>
									<h4>Total</h4>
								</Col>
								<Col xs={6} className="text-right">
									<h4>{window.rupeeSym + orderData?.order_bill.toFixed(2)}</h4>
								</Col>
							</Row>
						</Col>
						<Col sm={6} xs={12}>
							<div className="list-wrapper">
								<ListGroup>
									<ListGroupItem>
										<div>
											<h4 style={{ fontWeight: '400' }}>Contact:</h4>
										</div>
										<div>
											{orderData.allusers && orderData.allusers.length > 0
												? orderData.allusers.map((x) => (
														<h4>
															{(x ? x.first_name + ' ' : '') +
																(!context.selectedStaff ||
																(context.selectedStaff &&
																	window.server_roles[
																		context.selectedStaff.server_role
																	] >= managerAccessLevel)
																	? x
																		? x.phone
																		: ''
																	: '')}
														</h4>
												  ))
												: 'NA'}
										</div>
									</ListGroupItem>
									<ListGroupItem>
										<Row>
											<Col sm={6}>
												<div>
													<h4 style={{ fontWeight: '400' }}>
														Last Order Time:
													</h4>
												</div>
												<div>
													<h4>
														{new Date(orderData?.createdAt).toDateString() +
															' at ' +
															new Date(
																orderData?.createdAt
															).toLocaleTimeString()}
													</h4>
												</div>
											</Col>
										</Row>
									</ListGroupItem>
									<ListGroupItem>
										<div>
											<h4 style={{ fontWeight: '400' }}>Order ID:</h4>
										</div>
										<div>
											<h4>{`#${orderData?.order_id}`}</h4>
										</div>
									</ListGroupItem>
									<ListGroupItem>
										<div>
											<h4 style={{ fontWeight: '400' }}>Payment Mode:</h4>
										</div>
										<div>
											<h4>{orderData?.payment_mode}</h4>
										</div>
									</ListGroupItem>

									{orderData.order_type === 'delivery' ? (
										<ListGroupItem>
											<div>
												<h4 style={{ fontWeight: '400' }}>Delivery Address:</h4>
											</div>
											<div>
												<h4>
													{orderData.delivery_address
														? orderData.delivery_address.apt
															? orderData.delivery_address.apt +
															  ',' +
															  orderData.delivery_address.street
															: orderData.delivery_address.street
														: 'Not Available'}
												</h4>
											</div>
										</ListGroupItem>
									) : null}

									{userInfo ? (
										<>
											<ListGroupItem>
												<div>
													<h4 style={{ fontWeight: '400' }}>Total Orders</h4>
												</div>
												<div>
													<h4>{userInfo.total_orders}</h4>
												</div>
											</ListGroupItem>
											{userInfo.prev_items.length > 0 ? (
												<ListGroupItem>
													<div>
														<h4 style={{ fontWeight: '400' }}>
															Most Ordered :
														</h4>
													</div>
													<div>
														{userInfo.prev_items.map((x) => (
															<h4>{x.name}</h4>
														))}
													</div>
												</ListGroupItem>
											) : null}
										</>
									) : null}
								</ListGroup>
							</div>
						</Col>
					</Row>
				)}
			</ModalBody>
		</Modal>
	)
}

export default withTheme(OrderHistoryDetails)
