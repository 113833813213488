import React from 'react'
import { toast } from "react-toastify";
import axios from "axios";

export default class EachOrderController {
	static closeModal(e) {
		e.preventDefault()
		this.setState({ showModal: false })
	}

	static closeCheckoutModal() {
		this.setState({ showCheckoutModal: false })
	}

	static openCheckoutModal() {
		if (this.state.showOrderModal) {
			this.setState({ showCheckoutModal: true, showOrderModal: false })
		} else {
			this.setState({ showCheckoutModal: true })
		}
	}

	static closeDispatchModal() {
		this.setState({ showDispatchModal: false })
	}

	static openDispatchModal() {
		if (this.state.showOrdModal) {
			this.setState({ showDispatchModal: true, showOrderModal: false })
		} else {
			this.setState({ showDispatchModal: true })
		}
	}

	static closeCancelDeliveryModal() {
		this.setState({ showCancelDeliveryModal: false })
	}

	static openCancelDeliveryModal() {
		if (this.state.showOrderModal) {
			this.setState({
				showCancelDeliveryModal: true,
				showOrderModal: false,
			})
		} else {
			this.setState({ showCancelDeliveryModal: true })
		}
	}

	static closeOrderModal() {
		this.setState({ showOrderModal: false })
	}

	static openOrderModal() {
		this.setState({ showOrderModal: true })
	}

	static onChange(e) {
		this.setState({ [e.target.name]: e.target.value })
	}

	static async changeTable() {
		//console.log(this.state.orderdata.order_id, this.state.orderdata.table_id.id, this.state.newTableID);
		await this.props.context.changeTableDetails(
			this.state.orderdata.order_id,
			this.state.orderdata.table_id.id,
			this.state.newTableID
		)
		this.setState({ newTableID: null })
		this.props.context.loadRestaurantDetails()
	}

	static handleTableSelect(selectedOption) {
		//console.log(selectedOption);
		//var options = selectedOptions.map(opt => opt.value);
		this.setState({ newTableID: selectedOption.value })
	}

	static createOrder() {
		var filtered = this.props.context.restdata.tables.filter(
			(x) => x.id === this.state.orderdata.table_id.id
		)
		var tabledata = filtered[0]
		this.props.saveOrder(this.state.orderdata, tabledata)
		this.setState({ goNewOrder: true })
	}

	static async getOrder() {
		await new Promise((resolve, reject) => {
			if (this.props.orderID) {
				let url = window.kumbaURL + '/mainorders/sorted/' + this.props.orderID
				//console.log(this.props.orderID)
				//console.log(url)
				//console.log(this.props.context.io.socket)
				let header = { 'foodbots-header': this.props.token }
				axios({
					url,
					headers: header,
					method: 'GET',
				}).then((datum) => {
					let data = datum.data
					let inventory = {}
					data.orders.forEach((eachorder) => {
						if (eachorder.ingredients_per_quantity) {
							let ings = JSON.parse(eachorder.ingredients_per_quantity)
							ings.forEach((ing) => {
								if (!inventory[ing.id]) {
									inventory[ing.id] = {
										...ing,
										fullquantity: 0,
										orderquantity: 0,
										type: 'ingredient',
										orderNum: eachorder.item_id.recipe_code,
										description: '',
									}
								}
								inventory[ing.id].fullquantity +=
									ing.quantity * eachorder.quantity
								inventory[ing.id].orderquantity += eachorder.quantity
							})
						} else {
							console.log(eachorder.item_id)
							if (!inventory[eachorder.item_id.item_id]) {
								inventory[eachorder.item_id.item_id] = {
									id: eachorder.item_id.item_id,
									name: eachorder.item_id.name,
									uom: 'items',
									fullquantity: 0,
									orderquantity: 0,
									quantity: 1,
									type: 'item',
									orderNum: eachorder.item_id.orderNum,
									description: eachorder.item_id.description,
								}
							}
							inventory[eachorder.item_id.item_id].fullquantity +=
								eachorder.quantity
							inventory[eachorder.item_id.item_id].orderquantity +=
								eachorder.quantity
						}
					})

					this.props.setBatchedInventory(this.props.orderID, inventory)
					//console.log(data);
					this.setState({
						orderdata: data,
						discount: data.discount,
					})
					if (data.table_id && this.props.updateTblName) {
						this.props.updateTblName(data.table_id.table_name, 'tables')
					}
					if (data.delivery_slot && this.props.updateTblName) {
						this.props.updateTblName(
							' (' +
								new Date(data.delivery_slot.start_time).toLocaleString(
									'en-IN'
								) +
								'-' +
								new Date(data.delivery_slot.end_time).toLocaleString('en-IN') +
								')',
							'slots'
						)
					}
				})
				//var data = response.data;

				this.props.context.io.socket.request(
					{
						url,
						headers: header,
						method: 'GET',
					},
					function (data, jwres) {
						console.log(data, jwres)
						if (jwres.error) {
							console.log(jwres.error)
							toast('Error fetching order #' + this.props.orderID)
						} else {
							console.log(data.table_id)
							this.setState({
								orderdata: data,
								discount: data.discount,
							})
							if (data.table_id && this.props.updateTblName) {
								this.props.updateTblName(data.table_id.table_name, 'tables')
							}
							if (data.delivery_slot && this.props.updateTblName) {
								this.props.updateTblName(
									data.delivery_slot.name +
										' (' +
										new Date(data.delivery_slot.start_time).toLocaleString(
											'en-IN'
										) +
										'-' +
										new Date(data.delivery_slot.end_time).toLocaleString(
											'en-IN'
										) +
										')',
									'slots'
								)
							}
						}
					}.bind(this)
				)
				return resolve()
			} else {
				this.setState({ orderdata: null, discount: 0.0 })
				resolve()
			}
		})
	}

	static async applyDiscount() {
		try {
			if (this.props.orderID) {
				let url = window.kumbaURL + '/mainorders/discount/'
				let header = { 'foodbots-header': this.props.token }
				var toastID = toast('Applying Discount', {
					autoClose: false,
					closeButton: false,
				})
				var response = await axios({
					url,
					headers: header,
					method: 'PUT',
					data: {
						order_id: this.props.orderID,
						discount: this.state.discount,
					},
				})
				var data = response.data
				//console.log(data);
				toast.update(toastID, {
					render: 'Applied discount successsfully.',
					type: toast.TYPE.SUCCESS,
					autoClose: 1000,
					closeButton: true,
				})
				this.getOrder()
			}
		} catch (err) {
			//console.log(err);
			toast.update(toastID, {
				render: 'Something went wrong. Please try again.',
				type: toast.TYPE.ERROR,
				autoClose: 1000,
				closeButton: true,
			})
		}
	}

	static async finalizeOrder(payment_mode) {
		var data = {}
		try {
			if (this.state.orderdata) {
				this.closeCheckoutModal()
				var toastID = toast('Checking out ' + window.tableTerm + ' .....', {
					autoClose: false,
					closeButton: false,
				})
				let header = { 'foodbots-header': this.props.token }
				let url = window.kumbaURL + process.env.REACT_APP_CHECKOUT_ORDER_URI // "/razorpay/checkout/"; "/mainorders/pay"
				if (payment_mode) {
					data = {
						order_id: this.props.orderID,
						payment_mode: payment_mode,
						credits_used: 0.0,
					}
				} else {
					data = {
						order_id: this.props.orderID,
						credits_used: 0.0,
					}
				}

				const response = await axios({
					url,
					method: 'PUT',
					headers: header,
					data: data,
				})

				toast.update(toastID, {
					render: 'Checked out successsfully.',
					type: toast.TYPE.SUCCESS,
					autoClose: 1000,
					closeButton: true,
				})
				//this.setState({redirect : true, dialogOpen : false});
				this.setState({ orderdata: null, order_id: null })
				return await this.props.loadRestaurantDetails()
			} else {
				return Promise.resolve()
			}
		} catch (error) {
			toast.update(toastID, {
				render: 'Something went wrong. Please try again.',
				type: toast.TYPE.ERROR,
				autoClose: 1000,
				closeButton: true,
			})
			return new Promise((resolve, reject) => {
				return reject(error)
			})
		}
	}

	static async dispatchOrder(payment_mode) {
		var data = {}
		try {
			if (this.state.orderdata) {
				this.closeDispatchModal()
				var toastID = toast('Dispatching order .....', {
					autoClose: false,
					closeButton: false,
				})
				let header = { 'foodbots-header': this.props.token }
				let url = window.kumbaURL + '/delivery/create' // "/razorpay/checkout/"; "/mainorders/pay"

				data = {
					order_id: this.props.orderID,
					payment_mode: payment_mode,
				}

				const response = await axios({
					url,
					method: 'POST',
					headers: header,
					data: data,
				})

				if (this.props.paymentUpdate) {
					await this.props.context.removePaymentUpdate(this.props.orderID)
				}

				toast.update(toastID, {
					render: 'Dispatched successsfully.',
					type: toast.TYPE.SUCCESS,
					autoClose: 1000,
					closeButton: true,
				})
				//this.setState({redirect : true, dialogOpen : false});
				this.setState({ orderdata: null, order_id: null })
				return await this.props.loadRestaurantDetails()
			}
		} catch (error) {
			toast.update(toastID, {
				render: 'Something went wrong. Please try again.',
				type: toast.TYPE.ERROR,
				autoClose: 1000,
				closeButton: true,
			})
			return Promise.reject(error)
		}
	}

	static async cancelDeliveryOrder() {
		var data = {}
		try {
			if (this.state.orderdata) {
				this.closeCancelDeliveryModal()
				var toastID = toast('Canceling order .....', {
					autoClose: false,
					closeButton: false,
				})
				let header = { 'foodbots-header': this.props.token }
				let url = window.kumbaURL + '/delivery/cancel/' + this.props.orderID // "/razorpay/checkout/"; "/mainorders/pay"

				// data = {
				//     order_id: this.props.orderID,
				//     payment_mode: payment_mode,
				// };

				const response = await axios({
					url,
					method: 'DELETE',
					headers: header,
				})

				if (this.props.paymentUpdate) {
					await this.props.context.removePaymentUpdate(this.props.orderID)
				}

				toast.update(toastID, {
					render: 'Canceled successsfully.',
					type: toast.TYPE.SUCCESS,
					autoClose: 1000,
					closeButton: true,
				})
				//this.setState({redirect : true, dialogOpen : false});
				this.setState({ orderdata: null, order_id: null })
				return await this.props.loadRestaurantDetails()
			}
		} catch (error) {
			toast.update(toastID, {
				render: 'Something went wrong. Please try again.',
				type: toast.TYPE.ERROR,
				autoClose: 1000,
				closeButton: true,
			})
			return Promise.reject(error)
		}
	}

	static async markAcceptAll() {
		let header = { 'foodbots-header': this.props.token }
		var toastID = toast('Confirming order ...', {
			autoClose: false,
			closeButton: false,
		})
		try {
			var response = await axios({
				url: window.kumbaURL + '/orders/confirmed',
				headers: header,
				method: 'PUT',
				data: {
					orderID: this.props.orderID,
				},
			})
			var jsondata = response.data
			// this.props.clearOTP();
			// await this.props.getActiveOrders();
			toast.update(toastID, {
				render: 'Confirmed order successfully',
				type: toast.TYPE.SUCCESS,
				autoClose: 1000,
				closeButton: true,
			})
		} catch (error) {
			this.setState({ error: error })
			toast.update(toastID, {
				render: 'Failed to confirm order',
				type: toast.TYPE.ERROR,
				autoClose: 1000,
				closeButton: true,
			})
			// alert('Failed from waiting');
		}
	}

	static async markReject(id) {
		let header = { 'foodbots-header': this.props.token }
		var toastID = toast('Rejecting order ...', {
			autoClose: false,
			closeButton: false,
		})
		try {
			var response = await axios({
				url: window.kumbaURL + '/orders/markreject/' + id,
				headers: header,
				method: 'PUT',
			})
			var jsondata = response.data
			// await this.props.getActiveOrders();
			toast.update(toastID, {
				render: 'Notified waiters.',
				type: toast.TYPE.SUCCESS,
				autoClose: 1000,
				closeButton: true,
			})
		} catch (error) {
			this.setState({ error: error })
			toast.update(toastID, {
				render: 'Failed to notify waiters',
				type: toast.TYPE.ERROR,
				autoClose: 1000,
				closeButton: true,
			})
		}
	}

	static async markReadyForPickup() {
		let header = { 'foodbots-header': this.props.token }
		var toastID = toast('Updating ...', {
			autoClose: false,
			closeButton: false,
		})
		try {
			var response = await axios({
				url: window.kumbaURL + '/orders/readyforpickup/',
				headers: header,
				method: 'PUT',
				data: {
					orderID: this.props.orderID,
				},
			})
			var jsondata = response.data
			toast.update(toastID, {
				render: 'Updated.',
				type: toast.TYPE.SUCCESS,
				autoClose: 1000,
				closeButton: true,
			})
			return Promise.resolve(jsondata)
		} catch (error) {
			this.setState({ error: error })
			toast.update(toastID, {
				render: 'Failed to update.',
				type: toast.TYPE.ERROR,
				autoClose: 1000,
				closeButton: true,
			})
			return Promise.reject(error)
		}
	}

	static async markPrepareOrder() {
		let header = { 'foodbots-header': this.props.token }
		var toastID = toast('Updating ...', {
			autoClose: false,
			closeButton: false,
		})
		try {
			var response = await axios({
				url: window.kumbaURL + '/mainorders/acceptorder/' + this.props.orderID,
				headers: header,
				method: 'PUT',
			})
			var jsondata = response.data
			// await this.props.getActiveOrders();
			toast.update(toastID, {
				render: 'Updated.',
				type: toast.TYPE.SUCCESS,
				autoClose: 1000,
				closeButton: true,
			})
			return Promise.resolve(jsondata)
		} catch (error) {
			this.setState({ error: error })
			toast.update(toastID, {
				render: 'Failed to update.',
				type: toast.TYPE.ERROR,
				autoClose: 1000,
				closeButton: true,
			})
			return Promise.reject(error)
		}
	}

	static async markOrderReady() {
		let header = { 'foodbots-header': this.props.token }
		var toastID = toast('Updating ...', {
			autoClose: false,
			closeButton: false,
		})
		try {
			var response = await axios({
				url: window.kumbaURL + '/mainorders/orderready/' + this.props.orderID,
				headers: header,
				method: 'PUT',
			})
			var jsondata = response.data
			toast.update(toastID, {
				render: 'Updated.',
				type: toast.TYPE.SUCCESS,
				autoClose: 1000,
				closeButton: true,
			})
			await this.getOrder();
			return Promise.resolve(jsondata)
		} catch (error) {
			this.setState({ error: error })
			toast.update(toastID, {
				render: 'Failed to update.',
				type: toast.TYPE.ERROR,
				autoClose: 1000,
				closeButton: true,
			})
			return Promise.reject(error)
		}
	}

	static async makeAdjustments(amount, reason) {
		let header = { 'foodbots-header': this.props.token }
		var toastID = toast('Updating ...', {
			autoClose: false,
			closeButton: false,
		})
		try {
			var response = await axios({
				url: window.kumbaURL + '/mainorders/adjustments/' + this.props.orderID,
				headers: header,
				method: 'PUT',
                data : {
                    adjustment_amount: amount,
                    reason: reason
                }
			})
			var jsondata = response.data
			toast.update(toastID, {
				render: 'Updated.',
				type: toast.TYPE.SUCCESS,
				autoClose: 1000,
				closeButton: true,
			})
			await this.getOrder()
			return Promise.resolve(jsondata)
		} catch (error) {
			this.setState({ error: error })
			toast.update(toastID, {
				render: 'Failed to update.',
				type: toast.TYPE.ERROR,
				autoClose: 1000,
				closeButton: true,
			})
			return Promise.reject(error)
		}
	}
}
